import createSliceState from './common/createSliceState';

export default createSliceState({
  name: 'templateList',
  initialState: [],
  reducers: {
    set: (state, action) => state,
  },
  effects: {
    fetch: async (state) => {
      // if (USER.provideUserId && USER.provideCategoryId) {
      //   // Provided template
      //   const data = await article.getArticleList({
      //     userId: USER.provideUserId,
      //     categoryId: USER.provideCategoryId,
      //     page: 1,
      //     size: 1000,
      //   });
      //   provideUserData = data.list;
      // }

      // user data
      return undefined
    },
  },
});
