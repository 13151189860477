import createSliceState from './common/createSliceState';
import { history } from '@src/utils/history';
import { IBlockData, BlockManager, BasicType, AdvancedType } from 'easy-email-core';
import { IEmailTemplate } from 'easy-email-editor';
import { getTemplate } from '@src/config/getTemplate';

export function getAdaptor(data): IEmailTemplate {
  const content = JSON.parse(data.content.content) as IBlockData;
  return {
    ...data,
    content,
    subject: data.title,
    subTitle: data.summary,
  };
}

export default createSliceState({
  name: 'template',
  initialState: null as IEmailTemplate | null,
  reducers: {
    set: (state, action) => {
      return action.payload;
    },
  },
  effects: {
    fetchById: async (
      state,
      {
        id,
      }: {
        id: number;
      },
    ) => {
      try {
        let data = await getTemplate(id);
        return getAdaptor(data);
      } catch (error) {
        history.replace('/');
        throw error;
      }
    },
    fetchDefaultTemplate: async state => {
      return {
        subject: 'Welcome to Easy-email',
        subTitle: 'Nice to meet you!',
        content: BlockManager.getBlockByType(BasicType.PAGE).create({
          children: [BlockManager.getBlockByType(AdvancedType.WRAPPER).create()],
        }),
      } as IEmailTemplate;
    },
  },
});
