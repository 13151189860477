import React from 'react';
import { IconEdit } from '@arco-design/web-react/icon';
import dayjs from 'dayjs';
import styles from './index.module.scss';
import { Link } from 'react-router-dom';
import { pushEvent } from '@src/utils/pushEvent';
import { useLoading } from '@src/hooks/useLoading';
import { Loading } from '@src/components/loading';

interface CardItemProps {
  data: {
    article_id: number,
    title: string,
    picture: string,

  };
}

export function CardItem(props: CardItemProps) {
  const { data } = props;
  const loading = useLoading([]);

  return (
    <div
      key={data.article_id}
      className={styles.templeteItem}
      style={{ backgroundImage: `url(${data.picture})` }}
    >
      <div className={styles.bottom}>
        <div className={styles.title}>{data.title}</div>
      </div>
      <div className={styles.mask}>
        {loading ? (
          <div className={styles.listBottom}>
            <Loading loading color='#ffffff' />
          </div>
        ) : (
          <div className={styles.listBottom}>
            <div className={styles.listItem}>
              <Link
                to={`/editor?id=${data.article_id}`}
                onClick={() =>
                  pushEvent({
                    event: 'Edit',
                    payload: { article_id: data.article_id, title: data.title },
                  })
                }
              >
                <IconEdit />
                &nbsp;Edit
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
