import './axios.config';
import { common } from '@src/services/common';
import { user } from './user';

const services = {
  common,
  user,
};

export default services;
