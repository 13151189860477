

import templates from '@src/config/templates.json';

export async function getTemplate(id: string|number) {
  const item = templates.find(item => item.article_id === +id);
  if (!item) return null;
  let data:any = null;
  switch (item.path) {
    
  case 'Blank.json':
      data = (await import("@src/templates/Blank.json")).default;
      break;
  case 'Replyable.json':
      data = (await import("@src/templates/Replyable.json")).default;
      break
  }
  return data;
}


